angular.module('portainer.oauth').controller('OAuthProviderSelectorController', function OAuthProviderSelectorController() {
  var ctrl = this;

  this.providers = [
    {
      authUrl: 'https://sso.kin3tic.io/auth/realms/kin3tic-external/protocol/openid-connect/auth',
      accessTokenUrl: 'https://sso.kin3tic.io/auth/realms/kin3tic-external/protocol/openid-connect/token',
      resourceUrl: 'https://sso.kin3tic.io/auth/realms/kin3tic-external/protocol/openid-connect/userinfo',
      userIdentifier: 'email',
      scopes: 'openid email',
      name: 'Kin3tic ID',
      label: 'Kin3tic ID',
      description: 'Kin3tic ID SSO',
      icon: 'fa fa-user-check',
    },
  ];

  this.$onInit = onInit;

  function onInit() {
    if (ctrl.provider.authUrl) {
      ctrl.provider = getProviderByURL(ctrl.provider.authUrl);
    } else {
      ctrl.provider = ctrl.providers[0];
    }
    ctrl.onSelect(ctrl.provider, false);
  }

  function getProviderByURL(providerAuthURL) {
    if (providerAuthURL.indexOf('login.microsoftonline.com') !== -1) {
      return ctrl.providers[0];
    } else if (providerAuthURL.indexOf('accounts.google.com') !== -1) {
      return ctrl.providers[1];
    } else if (providerAuthURL.indexOf('github.com') !== -1) {
      return ctrl.providers[2];
    }
    return ctrl.providers[3];
  }
});
